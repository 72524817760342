.color1bg-dark {
  background-color: #4e9fda;
}
.color1bg {
  background-color: #d0e9f6;
}
.color1bg-light {
  background-color: #f1f9ff;
}

.color1-dark {
  color: #4e9fda;
}
.color1 {
  color: #d0e9f6;
}
.color1-light {
  color: #f1f9ff;
}

.color-gray {
  background-color: #f5f5f5;
}
.color-gray-light {
  background-color: #f2f2f2;
}

.color-gray-light-2 {
  background-color: #fafbfd;
}

.color-white-bg {
  background-color: #fff;
}

.color2bg-dark {
  background-color: #9482da;
}
.color2bg {
  background-color: #d8d0f6;
}

.color2bg-light {
  background-color: #eeebf7;
}

.color3bg {
  background-color: #fffbe8;
}

.color2-dark {
  color: #9482da;
}

.color-white {
  color: #fff;
}

.color-green-bg {
  background-color: #29894a;
}

.color-darkBlue-bg {
  background-color: #0f2d7a;
}

.color-black-bg {
  background-color: #000000;
}
.color-black {
  color: #000000;
}

.color-gray {
  color: #666666;
}

.card-border {
  border: 1px solid #dedede;
}

.card-lightBlue-border {
  border: 2px solid #cae2ff;
}

.card-yellow-border {
  border: 1px solid #f5edd7;
}

.color-bg4 {
  background-color: #f8f9fa;
}

.lateAttendance {
  color: rgb(128, 0, 128);
}
.halfDayAttendance {
  color: rgb(71, 71, 209);
}
