body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}
.scrollBar-hide::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.th-drawer-white .MuiDrawer-paper {
  background-color: #ffffff00 !important;
  box-shadow: none !important;
}
.th-drawer-white .MuiBackdrop-root {
  z-index: 0 !important;
}

.box-shadow {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}

.MuiInputBase-root {
  border-radius: 20px !important;
}

.diary-static-datepicker .MuiDialogActions-root {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.siq_bL {
  bottom: 132px !important;
  left: inherit !important;
  right: 15px !important;
}
