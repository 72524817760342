.comp-slick .slick-dots {
  position: unset !important;
  display: flex !important;
  justify-content: center !important;
}

/* hiding all bullets by default */
.comp-slick .slick-dots li {
  display: none !important;
}
/* only displaying the active bullets and the 2 bullets next to it */
.comp-slick .slick-dots li.slick-active,
.comp-slick .slick-dots li.slick-active + li,
.comp-slick .slick-dots li.slick-active + li + li {
  display: block !important;
}
/* displaying the last three bullets when slick-active class isn't applied to any li before them  */
.comp-slick .slick-dots li:nth-last-child(1),
.comp-slick .slick-dots li:nth-last-child(2),
.slick-dots li:nth-last-child(3) {
  display: block !important;
}
/* hiding the last three bullets if slick-active exist before them */
.slick-dots li.slick-active ~ li:nth-last-child(1),
.slick-dots li.slick-active ~ li:nth-last-child(2),
.slick-dots li.slick-active ~ li:nth-last-child(3) {
  display: none !important;
}
/* specific conditions to always display the last three bullets */
.slick-dots li.slick-active + li + li:nth-last-child(3),
.slick-dots li.slick-active + li + li:nth-last-child(2),
.slick-dots li.slick-active + li + li:nth-last-child(1),
.slick-dots li.slick-active + li:nth-last-child(3),
.slick-dots li.slick-active + li:nth-last-child(2),
.slick-dots li.slick-active + li:nth-last-child(1) {
  display: block !important;
}
